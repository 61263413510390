<template>
  <div class="contBox">
    <v-container class="fill-height">
      <v-row>
        <div class="ma-auto">
          <v-card class="px-8 pt-6 pb-12" outlined>
            <v-card-title class="text-center mb-4">绑定账号</v-card-title>
            <v-card-text>
              <ValidationObserver ref="form" v-slot="{ handleSubmit, reset }">
                <form @submit.prevent="handleSubmit(signin)" @reset.prevent="reset">
                  <ValidationProvider v-slot="{ errors }" name="账号" rules="required|account">
                    <v-text-field class="form_inp" v-model="email" rounded :error-messages="errors" label="邮箱或手机号" solo>
                    </v-text-field>
                  </ValidationProvider>
                  <ValidationProvider v-slot="{ errors }" name="密码" rules="required">
                    <v-text-field class="form_inp" v-model="password" rounded type="password" :error-messages="errors"
                      label="密码" solo>
                    </v-text-field>
                  </ValidationProvider>
                  <div class="mb-4 d-flex justify-space-between">
                    <v-btn text small class="pl-0 text-capitalize" color="primary" router to="signup">
                      注册账号
                    </v-btn>
                  </div>
                  <v-btn type="submit" class="submit-btn mt-5" block rounded depressed>绑定</v-btn>
                </form>
              </ValidationObserver>
            </v-card-text>
          </v-card>
        </div>
      </v-row>
    </v-container>
    <v-snackbar v-model="isShow" :timeout="timeout">
      {{showToast}}
    </v-snackbar>
  </div>
</template>

<script>
export default {
  name: "SignIn",
  data: () => ({
    email: "",
    password: "",
    confirmPassword: "",
    code: "",
    isShow: false,
    showToast: '',
    timeout: 1500,
    timestamp: 0,
    mandate: {},
  }),
  mounted() {
    this.mandate = this.GetRequest();
  },
  methods: {
    GetRequest() {
      const url = window.location.href.split('?')[1]; //获取url中"?"符后的字串
      let theRequest = new Object();
      if (url) {
        let strs = url.split("&");
        for (let i = 0; i < strs.length; i++) {
          theRequest[strs[i].split("=")[0]] = unescape(strs[i].split("=")[1]);
        }
      }
      return theRequest;
    },
    countDown() {
      this.timestamp--;
      setTimeout(() => {
        if (this.timestamp > 0) {
          this.countDown()
        }
      }, 1000)
    },
    updateVerify() {
      this.$ajax.post('/api/Login/released', {
        'username': this.email
      }).then(res => {
        if (res.code == 1) {
          this.timestamp = 60;
        }
        this.countDown();
        this.showToast = res.msg;
        this.isShow = true;
      })
    },
    signin() {
      this.$ajax.post("/api/Login/pcbinding", {
        username: this.email,
        password: this.password,
        equipment: "PC",
        type: this.mandate.type,
        openid: this.mandate.openid,

      }).then((res) => {
        console.log(res);
        if (res.code == 1) {
          this.vuex("vuex_token", res.token);
          setTimeout(() => {
            this.$router.push('/')
          }, 1500);
        }
        this.showToast = res.msg;
        this.isShow = true;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.contBox {
  height: 100%;
  width: calc(100% - 40px);
  max-width: 100%;
  margin: 0 20px;
  background: url("../../assets/login/login_bg.png") no-repeat;
  background-size: 100% auto;
  .headLogin {
    width: 120px;
  }
  & ::v-deep .v-input__control .v-input__slot {
    box-shadow: none !important;
    background: #efefef;
    width: 383px;
    height: 56px;
    margin-bottom: 4px;
  }
  .submit-btn {
    background: #d10d2b;
    color: #fff;
    width: 383px;
    height: 56px;
    background: #d10d2b;
    border-radius: 28px;
  }
  & ::v-deep .v-input__append-inner {
    margin: 0;
  }
  & ::v-deep .v-label {
    font-size: 15px;
  }
}
</style>
