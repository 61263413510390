<template>
  <div class="contBox">
    <v-container class="fill-height">
      <v-row>
        <div class="ma-auto">
          <v-card class="px-8 pt-6 pb-12" outlined>
            <v-card-title class="text-center mb-4">忘记密码</v-card-title>
            <!-- <v-card-subtitle class="mb-5">Sign in</v-card-subtitle> -->
            <!-- <div class="text-center"><img src="@/assets/login/logo.png" class="headLogin" /></div> -->
            <v-card-text>
              <ValidationObserver ref="form" v-slot="{ handleSubmit, reset }">
                <form @submit.prevent="handleSubmit(signin)" @reset.prevent="reset">
                  <ValidationProvider v-slot="{ errors }" name="账号" rules="required|account">
                    <v-text-field class="form_inp" v-model="email" rounded :error-messages="errors" label="邮箱或手机号" solo>
                    </v-text-field>
                  </ValidationProvider>
                  <ValidationProvider v-slot="{ errors }" name="验证码" rules="required">
                    <div class="d-flex">

                      <v-text-field class="form_inp" v-model="code" appen="aaa" rounded type="text"
                        :error-messages="errors" label="验证码" solo>
                        <template v-slot:append>
                          <div class="d-flex">
                            <v-btn text small class="pl-0 text-capitalize" color="primary" v-if="timestamp==0"
                              @click="updateVerify">发送验证码</v-btn>
                            <span v-else>{{timestamp}} 秒后重发</span>
                          </div>
                        </template>
                      </v-text-field>
                    </div>
                  </ValidationProvider>
                  <ValidationProvider v-slot="{ errors }" name="新密码" rules="required">
                    <v-text-field class="form_inp" v-model="password" rounded type="password" :error-messages="errors"
                      label="新密码" solo>
                    </v-text-field>
                  </ValidationProvider>
                  <ValidationProvider v-slot="{ errors }" name="确认新密码" rules="required|password:@新密码">
                    <v-text-field class="form_inp" v-model="confirmPassword" rounded type="password"
                      :error-messages="errors" label="确认新密码" solo>
                    </v-text-field>
                  </ValidationProvider>

                  <v-btn type="submit" class="submit-btn mt-5" block rounded depressed>确定</v-btn>
                </form>
              </ValidationObserver>
            </v-card-text>
          </v-card>
        </div>
      </v-row>
    </v-container>
    <v-snackbar v-model="isShow" :timeout="timeout">
      {{showToast}}
    </v-snackbar>
  </div>
</template>

<script>
export default {
  name: "SignIn",
  data: () => ({
    email: "",
    password: "",
    confirmPassword: "",
    code: "",
    isShow: false,
    showToast: '',
    timeout: 1500,
    timestamp: 0
  }),
  mounted() {

  },
  methods: {
    countDown() {
      this.timestamp--;
      setTimeout(() => {
        if (this.timestamp > 0) {
          this.countDown()
        }
      }, 1000)
    },
    updateVerify() {
      this.$ajax.post('/api/Login/released', {
        'username': this.email
      }).then(res => {
        if (res.code == 1) {
            this.timestamp = 60;
           this.countDown();
        }
       
        this.showToast = res.msg;
        this.isShow = true;
      })
    },
    signin() {
      this.$ajax.post("/api/Login/reset", {
        username: this.email,
        password: this.password,
        confirm: this.confirmPassword,
        equipment: "PC",
        code: this.code,
      }).then((res) => {
        console.log(res);
        if (res.code == 1) {
          this.vuex("vuex_token", res.token);
          setTimeout(() => {
            this.$router.push('/')
          }, 1500);
        }
        this.showToast = res.msg;
        this.isShow = true;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.contBox {
  height: 100%;
  width: calc(100% - 40px);
  max-width: 100%;
  margin: 0 20px;
  background: url("../../assets/login/login_bg.png") no-repeat;
  background-size: 100% auto;
  .headLogin {
    width: 120px;
  }
  & ::v-deep .v-input__control .v-input__slot {
    box-shadow: none !important;
    background: #efefef;
    width: 383px;
    height: 56px;
    margin-bottom: 4px;
  }
  .submit-btn {
    background: #d10d2b;
    color: #fff;
    width: 383px;
    height: 56px;
    background: #d10d2b;
    border-radius: 28px;
  }
  & ::v-deep .v-input__append-inner {
    margin: 0;
  }
  & ::v-deep .v-label {
    font-size: 15px;
  }
}
</style>
