var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"contBox"},[_c('v-container',{staticClass:"fill-height"},[_c('v-row',[_c('div',{staticClass:"ma-auto"},[_c('v-card',{staticClass:"px-8 pt-6 pb-12",attrs:{"outlined":""}},[_c('v-card-title',{staticClass:"text-center mb-4"},[_vm._v("绑定账号")]),_c('v-card-text',[_c('ValidationObserver',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
var reset = ref.reset;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.signin)},"reset":function($event){$event.preventDefault();return reset.apply(null, arguments)}}},[_c('ValidationProvider',{attrs:{"name":"账号","rules":"required|account"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"form_inp",attrs:{"rounded":"","error-messages":errors,"label":"邮箱或手机号","solo":""},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"密码","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"form_inp",attrs:{"rounded":"","type":"password","error-messages":errors,"label":"密码","solo":""},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}})]}}],null,true)}),_c('div',{staticClass:"mb-4 d-flex justify-space-between"},[_c('v-btn',{staticClass:"pl-0 text-capitalize",attrs:{"text":"","small":"","color":"primary","router":"","to":"signup"}},[_vm._v(" 注册账号 ")])],1),_c('v-btn',{staticClass:"submit-btn mt-5",attrs:{"type":"submit","block":"","rounded":"","depressed":""}},[_vm._v("绑定")])],1)]}}])})],1)],1)],1)])],1),_c('v-snackbar',{attrs:{"timeout":_vm.timeout},model:{value:(_vm.isShow),callback:function ($$v) {_vm.isShow=$$v},expression:"isShow"}},[_vm._v(" "+_vm._s(_vm.showToast)+" ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }